<template>
    <div>
        <div class="row">
            <div class="col-lg-6">
                <h3 class="config-header">Config: Settings</h3>
                
                <div v-if="!fetchClientSettingsLoading">
                    <b>Proactive Monitoring</b>
                    <DefaultSnoozeSettings v-model:defaultSnoozeDays="promonDefaultSnoozeDays"
                        :isSavingItem="isSavingItem(ClientSettings.promon_default_snooze_days)"
                        :inputID="'promonDefaultSnoozeDays'" :settingsName="ClientSettings.promon_default_snooze_days"
                        @handleBlur="handleBlur" @handleFocus="handleFocus" />


                    <b>Application Vetter</b>
                    <template v-if="enableAppVetter">
                        <DefaultSnoozeSettings v-model:defaultSnoozeDays="appvetDefaultSnoozeDays"
                            :isSavingItem="isSavingItem(ClientSettings.application_default_snooze_days)"
                            :inputID="'appvetDefaultSnoozeDays'"
                            :settingsName="ClientSettings.application_default_snooze_days" @handleBlur="handleBlur"
                            @handleFocus="handleFocus" />
                    </template>
                </div>
                <div v-else>
                    <div class="card my-3" v-for="index in 2" :key="index">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <label for="skeleton">
                                        <Skeleton width="10rem" height="1rem" class="mb-2"></Skeleton>
                                    </label>
                                    <br>
                                    <div class="d-inline-flex">
                                        <Skeleton width="12rem" height="1rem" class="me-2"></Skeleton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue"
import { toast } from "@/helpers/toast"
import { ClientSettings, getApiErrorMessage } from "@/helpers/common"
import { useAPI } from "@/helpers/services/api"
import { useAdminDetails } from "@/stores/adminDetails"
import { useProfile } from "@/stores/profile"
import DefaultSnoozeSettings from "@/components/Admin/Shared/DefaultSnoozeSettings.vue"
import Skeleton from "primevue/skeleton"

const api = useAPI()
const storeAdminDetails = useAdminDetails()
const storeProfile = useProfile()
const currentCustomerAccess = computed(() => (storeProfile.getCustomerAccess))
type TypeClientSettings = boolean | string | null

// SETTINGS
const promonDefaultSnoozeDays = ref<any>(null)
const appvetDefaultSnoozeDays = ref<any>(null)
const enableAppVetter = ref<TypeClientSettings>(null)

// OTHER STATE
const fetchClientSettingsLoading = ref(true)
const currentItemOriginalValue = ref("")
const isSaving = ref(false)
const savingPropertyName = ref("")

const fetchClientSettingsWithLoading = async () => {
    fetchClientSettingsLoading.value = true
    await fetchClientSettings()
    fetchClientSettingsLoading.value = false
}

const isSavingItem = (name: string): boolean => (isSaving.value && savingPropertyName.value === name)

const fetchClientSettings = async () => {
    const promonRes = await storeAdminDetails.fetchClientSettingsItem(ClientSettings.promon_default_snooze_days, loadFailureHandler)
    if (promonRes.value) {
        promonDefaultSnoozeDays.value = promonRes.value
    }

    const appvetRes = await storeAdminDetails.fetchClientSettingsItem(ClientSettings.application_default_snooze_days, loadFailureHandler)
    if (appvetRes.value) {
        appvetDefaultSnoozeDays.value = appvetRes.value
    }
    enableAppVetter.value = currentCustomerAccess.value?.application_vetter as boolean
}

const handleFocus = (event: FocusEvent) => {
    const input = event.target as HTMLInputElement
    currentItemOriginalValue.value = input.value
}

const handleBlur = (event: FocusEvent, property: string = "") => {
    const input = event.target as HTMLInputElement
    if (input.value === currentItemOriginalValue.value) return

    const name = (input.dataset?.property || property) as ClientSettings
    if (!name) {
        alert("Configuration error. Element is not configured with a property name")
        return
    }
    updateSetting(name, input.value as ClientSettings)
}

const updateSetting = async (settingsName: ClientSettings, value: any) => {
    savingPropertyName.value = settingsName
    isSaving.value = true

    try {
        const payload = value
        await api.patch(`/settings/client/${settingsName}`, { "value": payload })

        toast.success("Saved!")
        storeProfile.fetchCustomerAccess()
    } catch (error: any) {
        toast.error(getApiErrorMessage(error))
    }

    isSaving.value = false
    savingPropertyName.value = ""
}


const loadFailureHandler = (error: any) => {
    const message = getApiErrorMessage(error, { "featureName": "Config: Settings" })
    toast.error(message)
}

onMounted(async () => {
    await fetchClientSettingsWithLoading()
})
</script>
