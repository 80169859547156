<template>
    <aside>
        <label class="admin-sidebar-header">{{ props.header }}</label>
        <ul class="menu">
            <li v-for="config in props.menu" :key="config.menu" :value="config.menu" class="menu-item"
                :class="{ 'active': config.page == selectedSubPage, 'tr-settings-icon': showTrueRollIcon(config) }" @click="selectSubPage(config.page)">
                {{ config.menu }}
            </li>
        </ul>
    </aside>
</template>

<script setup lang="ts">
import type { SubMenuConfig } from "@/helpers/interface/admin-page"

const props = defineProps({
    menu: {
        type: Array as () => SubMenuConfig[],
        required: true
    },
    header: String,
    selectedSubPage: String
})

const emits = defineEmits(['changeSubPage'])

const showTrueRollIcon = (config: SubMenuConfig) => {
    /* If the feature requires any of these internal-only permissions,
        then show the TrueRoll logo in the menu
    */
    const internalPermissions = [
        "read:customers", 
        "read:ingestion_configs", 
        "read:reports_assessed_value"
    ]
    return config.requiredPermissions?.some(x => internalPermissions.includes(x))
}

function selectSubPage(page: String) {
    emits("changeSubPage", page)
}
</script>

<style>
@import "@/assets/admin-page.css";
</style>